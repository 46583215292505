import * as Sentry from "@sentry/react";
import _ from "lodash";
import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import {
  isRouteErrorResponse,
  useFetcher,
  useRevalidator,
  useRouteError
} from "react-router";
import Alert from "~/components/alert";
import Link from "~/components/link";

export const useFetcherData = <T,>(
  path?: string,
  params?: Record<
    string,
    string | boolean | number | string[] | null | undefined
  >
) => {
  const fetcher = useFetcher<T>();

  // Re-fetch when params change
  const ref = useRef(JSON.stringify(params));
  const urlRef = useRef(path);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (
      (fetcher.state === "idle" && !fetcher.data) ||
      ref.current !== JSON.stringify(params) ||
      urlRef.current !== path
    ) {
      // Getting some BodyStreamBuffer was Aborted errors, so we're logging this
      Sentry.addBreadcrumb({
        category: "fetcher",
        message: "fetcher data changed",
        data: { params, path }
      });
      ref.current = JSON.stringify(params);
      urlRef.current = path;
      const search = createURLSearchParams(params || {});
      if (path) {
        fetcher.load(search.toString() ? `${path}?${search.toString()}` : path);
      }
    }
  }, [fetcher.state, fetcher.data, params, path]);
  return fetcher;
};

export const createURLSearchParams = (
  params: Record<
    string,
    string | boolean | number | string[] | null | undefined
  >
) => {
  const search = new URLSearchParams();
  Object.entries(params || {}).forEach(([key, value]) => {
    if (_.isArray(value)) {
      value.forEach((v) => search.append(key, v));
    } else if (value !== undefined) {
      search.append(key, value as string);
    }
  });
  return search;
};

export const DefaultErrorBoundary = () => {
  const error = useRouteError();
  if (!(isRouteErrorResponse(error) && [403, 404].includes(error.status))) {
    console.log("Sending error to Sentry", isRouteErrorResponse(error), error);
    Sentry.captureException(error);
  } else {
    console.log(
      "Not sending error to Sentry",
      isRouteErrorResponse(error),
      error
    );
  }

  return (
    <>
      <Alert mode="danger">
        {isRouteErrorResponse(error) ? (
          error.status === 404 ? (
            <p>
              The item you're trying to access could not be found. It may have
              been deleted.
            </p>
          ) : (
            <p>
              {_.isString(error.data) ? error.data : JSON.stringify(error.data)}
            </p>
          )
        ) : (
          <div className="space-y-4">
            <div>We're sorry — something's gone wrong.</div>
            <div>Dylan has been notified and will look into this.</div>
            {(error as Error).message && (
              <div>Details: {(error as Error).message}</div>
            )}
          </div>
        )}
      </Alert>
      {!isRouteErrorResponse(error) && (
        <Alert>
          If you had unsaved data before seeing this error, you can review the{" "}
          <Link className="font-bold" to="/auto-saves">
            Auto Saves
          </Link>{" "}
          page to recover any data.
        </Alert>
      )}
    </>
  );
};

export type MyHandle =
  | undefined
  | {
      name: string;
    };

let hydrating = true;

/**
 * Return a boolean indicating if the JS has been hydrated already.
 * When doing Server-Side Rendering, the result will always be false.
 * When doing Client-Side Rendering, the result will always be false on the
 * first render and true from then on. Even if a new component renders it will
 * always start with true.
 *
 * Example: Disable a button that needs JS to work.
 * ```tsx
 * let hydrated = useHydrated();
 * return (
 *   <button type="button" disabled={!hydrated} onClick={doSomethingCustom}>
 *     Click me
 *   </button>
 * );
 * ```
 */
export function useHydrated() {
  const [hydrated, setHydrated] = useState(() => !hydrating);

  useEffect(function hydrate() {
    hydrating = false;
    setHydrated(true);
  }, []);

  return hydrated;
}

type ClientOnlyProps = {
  /**
   * You are encouraged to add a fallback that is the same dimensions
   * as the client rendered children. This will avoid content layout
   * shift which is disgusting
   */
  children(): ReactNode;
  fallback?: ReactNode;
};

/**
 * Render the children only after the JS has loaded client-side. Use an optional
 * fallback component if the JS is not yet loaded.
 *
 * Example: Render a Chart component if JS loads, renders a simple FakeChart
 * component server-side or if there is no JS. The FakeChart can have only the
 * UI without the behavior or be a loading spinner or skeleton.
 * ```tsx
 * return (
 *   <ClientOnly fallback={<FakeChart />}>
 *     {() => <Chart />}
 *   </ClientOnly>
 * );
 * ```
 */
export function ClientOnly({ children, fallback = null }: ClientOnlyProps) {
  return useHydrated() ? <>{children()}</> : <>{fallback}</>;
}

/**
 * Revalidates a route repeatedly until a condition is met. Useful for
 * monitoring the status of a long-running process.
 *
 * @param condition - The condition that determines if the route should be re-validated
 * @param {number} interval - The interval in ms at which the route should be re-validated (default is 5 seconds)
 * @returns null
 */
export const useRevalidateOnInterval = (
  condition: boolean,
  interval = 5000
) => {
  const revalidator = useRevalidator();
  useEffect(() => {
    let t: number;
    if (condition) {
      t = window.setTimeout(() => {
        revalidator.revalidate();
      }, interval);
    }
    return () => {
      t && window.clearTimeout(t);
    };
  }, [condition, interval, revalidator]);

  return null;
};
